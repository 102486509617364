import React, { useEffect, useState } from 'react'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const Upcoming: React.FC = () => {
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    console.log(currentDate)
    const mayFirst = new Date(currentDate.getFullYear(), 4, 1); // Months are 0-indexed, so 4 is May
    console.log(currentDate >= mayFirst)
    if (currentDate >= mayFirst) {
      setShouldHide(true);
    }
  }, []);

  //   https://shop-thehouse.square.site/events

  // Jade R. Rogers  to  Everyone 3:12 PM
  // Linktree Thehouse402  tr3kn3xtg3n

  // Victoria Jones 3:30 PM
  // The House Game Night is back! Whether you're a trivia titan, board game enthusiast, a reluctant spades player, a dominoes dominator, just a social butterfly you are welcome at the table. Join us for a night of fun & friendly competition!

  // 5:00pm — Doors Open 
  // 6:00pm — Mixxer + Hors Dourves 
  // 6:30pm — Black History Trivia
  // 7:00pm — Open Board Games
  // 9:00pm — Werewolves of Miller Park
  const events: any = [
    {
      title: `GameNight`,
      location: 'Metro',
      date: 'Saturday February 8th, 2025 - 5pm to 10pm',
      image: './img/events/febgamenight.png',
      link: 'http://shop-thehouse.square.site/events',
      description: `The House Game Night is back! Whether you're a trivia titan, board game enthusiast, a reluctant spades player, a dominoes dominator, just a social butterfly you are welcome at the table. Join us for a night of fun & friendly competition!
`,
    },
    {
      title: `Umoja party`,
      location: 'Metro',
      date: 'Saturday February 22nd, 2025 - 6pm to 11pm',
      image: './img/events/umojaparty.png',
      link: 'http://shop-thehouse.square.site/events',
      description: `The House of Afros, Capes & Curls is proud to present UMOJA, a community gathering celebrating the rich history and heritage of African, African American, Afro-Latin, and Afro-Caribbean communities in Omaha. This event aims to share and explore the cultural connections across these vibrant communities.
Join us as we come together to honor our shared histories, celebrate our diverse cultures, and strengthen the bonds within our community.
`,
    },
    {
      title: `Cocktails & Comics`,
      location: 'Green Line Coffee, 1540 N Saddle Creek Rd, Omaha, NE 68104',
      date: 'Saturday March 22nd, 2025 - 5pm to 9pm',
      image: './img/events/cocktailscomics.png',
      link: 'http://shop-thehouse.square.site/events',
      description: `Step into a world where comics and cocktails collide in a perfect blend of engaging conversation and our favorite reads. Whether you're newly interested or a veteran comics enthusiast, you're welcome to join us for a unique experience that combines your love for comics with a refreshing twist - cheers to cocktails & comics!.
`,
    },

  ]


  return (
    <section className="page-section" id="upcoming">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h2 className="section-heading text-uppercase">
                Our Upcoming Events
              </h2>


              <div className="row justify-content-center">
                {events.length > 0 ? events.map((event: any, idx: number) => (
                  <div className="col-4" key={idx}>
                    <div className="card">
                      <img src={event.image} className="card-img-top" alt="..." />
                      <div className="card-body">
                        <h5 className="card-title">{event.title}</h5>
                        <p className="card-text" style={{overflow:'visible', height:'100%', display:'block'}}>{event.description}</p>
                        <p className="card-text">{event.date}</p>
                        <a href={event.link} className="btn btn-primary" style={{background:"#000"}}>RSVP</a>
                      </div>
                    </div>
                  </div>
                  // <div className="col-xs-6 col-md-6" key={idx}>
                  //   <a href={event.link} className="thumbnail d-flex justify-content-center" target="_blank" rel="noreferrer">
                  //     <img src={event.image} className="img-fluid rounded-start event-img" alt="..." style={{width:"300px"}}/>
                  //   </a>
                  //   <div className="forms d-flex justify-content-around mt-2">
                  //   <div style={{fontSize:"20px"}}>{event.description}<br/>More information coming soon!</div>
                  //   </div>
                  // </div>

                ))

                  : (<h3>Check back soon for more events!</h3>)}

              </div>

            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Upcoming
